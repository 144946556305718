.RoomProjectMenu {
	//color: $color-black;
	color: $color-white;
	opacity: 0;
	display: none;
	pointer-events: none;

	&.open {
		.menu-items {
			z-index: 21;
			pointer-events: auto;
		}

		.unit-info {
			z-index: 22;

			body.isTouch & {
				padding-top: 0;
			}
		}

		.menu-opener {
			display: none;

			body.isTouch & {
				display: block;
			}
		}
	}

	.mask {
		width: 360px;
		height: 100%;
	}

	.menu-items {
		position: absolute;
		left: 0;
		bottom: 0px;
		z-index: 12;
		pointer-events: none;
		padding-top: 150px;
		padding-left: 20px;
		//padding-bottom: 100px;
		width: 300px;

		@media (min-width: $media-breakpoint-md) {
			width: 490px;
			//padding-bottom: 130px;
			left: 20px;
		}

		@media (min-width: $media-breakpoint-lg) {
			//padding-bottom: 160px;
			left: 60px;
		}

		@media (min-width: $media-breakpoint-xxl) {
			//padding-bottom: 180px;
			left: 60px;
		}

		//background-color: rgba(255,0,0,.1);
	}

	.menu-item {
		padding: 8px 0px;
		max-width: 400px;
		cursor: pointer;
		width: 100%;
		opacity: 0.5;

		body.isTouch & {
			opacity: 1;
		}

		p {
			@extend .type-title--body;
			width: 100%;
		}

		&.hidden {
			pointer-events: none;
			opacity: 0;

			body.isTouch & {
				opacity: 0;
			}
		}

		&.active {
			opacity: 1;
		}

		&.hovered {
			opacity: 1;
		}

		.project-title {
			font-weight: 500;
		}

		.student-name {
			font-weight: normal;
		}
	}

	.unit-info {
		cursor: pointer;
		position: fixed;
		bottom: 0;
		padding-top: 110px;
		z-index: 12;
		pointer-events: none;
		max-width: calc(100vw - 80px);
		padding-left: 20px;
		padding-bottom: 23px;

		@media (min-width: $media-breakpoint-md) {
			max-width: calc(490px - #{$site-padding-desktop});
			padding-bottom: 30px;
			left: 20px;
		}

		@media (min-width: $media-breakpoint-lg) {
			padding-bottom: 60px;
			left: 60px;
		}

		@media (min-width: $media-breakpoint-xxl) {
			padding-bottom: 60px;
			left: 60px;
		}

		p,
		a {
			@extend .type-title--body;
		}

		p {
			min-width: 250px;
		}

		a {
			color: $color-black;
			pointer-events: auto;

			&:hover {
				border-bottom: 0;
			}
		}

		.unit-code {
			font-weight: 500;
		}

		.unit-title,
		.unit-link {
			font-weight: normal;
		}

		.unit-link {
			position: relative;
			z-index: 32;
			color: $color-cta;
			font-weight: 500;

			&:hover {
				//color: $color-black;
				color: $color-white;
				text-decoration: underline;
			}
		}

		.menu-opener {
			position: absolute;
			pointer-events: auto;
			bottom: 0;
			left: 0;
			z-index: 31;
			opacity: 0;
			cursor: pointer;
			height: 100%;
			width: 100%;
			//background-color: rgba(255,0,0,.5);
		}
	}
}
