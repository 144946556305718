.EditorialPage {

	.title-body {
		ol,
		ul {
			list-style-position: inside;
			@extend .type-p--small;
		}

		ul {
			list-style-type: disc;
		}

		ol {
			list-style-type: decimal;
		}
	}
}
