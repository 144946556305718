.PanZoomModule {
	position: relative;

	&.zoomed {
		z-index: 2;
		.close {
			display: block;
		}

		//.poster-wrapper {
		//	display: none;
		//}

		canvas {
			display: block;
			cursor: grab;
		}
	}

	.poster-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-height: 100vh;
		overflow: hidden;

		.pill-button {
			position: absolute;
			z-index: 1;
		}
	}

	img.poster {
		cursor: pointer;
		transition: 0.5s opacity;
		position: relative;
		top: 0;
		left: 0;
		width: 100%;

		&.lazyloaded {
			opacity: 1;
		}
	}

	img.detail {
		display: none;
	}

	.loading {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 5;
		background-color: $color-black;
		width: 100%;
		height: 100%;
		visibility: hidden;
		opacity: 0;
	}

	.close {
		position: fixed;
		top: 0;
		z-index: 2;
		right: 20px;
		@extend .site-element-padding-right;
		color: $color-black;
		font-weight: 500;
		font-size: 16px;
		display: none;

		&.light {
			color: $color-white;
		}
	}

	canvas {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;
		cursor: pointer;
		display: none;
		margin: 0 auto;
	}

	.copy {
		grid-column-start: 2;
		grid-column-end: span 10;
	}
}
