@import 'eases';

$color-brand: #11362a !default;
$color-black: #131214;
$color-white: #ffffff;
$color-white: #ffffff;
$color-mercury: #e5e5e5;
$color-sand: #D4D1C3;
$color-gray: #828282;
$color-gray-light: #f9f9f9;
$color-off-black: #17181a;
$color-border-on-light: #ececec;
$color-cta: #b1a475;
$color-image-placeholder-on-light: #eee;
$color-image-placeholder-on-dark: #000;
$color-border-on-dark: #424244;
$color-loader1: #2D3326;
$color-loader2: #654E2C;
$color-background: #0F2125;

// Animation
$default-ease: $ease-in-out-quad;

// Media breakpoints
// (NOTE: Always use @media (min-width) when using these variables.)
$media-breakpoint-xs: 0 !default;
$media-breakpoint-xsm: 375px !default;
$media-breakpoint-sm: 576px !default;
$media-breakpoint-md: 800px !default;
$media-breakpoint-lg: 1024px !default;
$media-breakpoint-xl: 1440px !default;
$media-breakpoint-xxl: 1921px !default;

$max-page-width: 1660px;
$site-padding-desktop: 80px;
$site-padding-tablet: 40px;
$site-padding-mobile: 20px;

// Elements
$header-height: 60px !default;
$input-height: 45px;
$input-padding: 25px;
