.SoundModule {
	.wrapper {
		grid-column-start: 2;
		grid-column-end: span 10;
		@include to-grid(padding-left, 10, 1);
		@include to-grid(padding-right, 10, 1);
		padding-top: 80px;
		padding-bottom: 100px;
		background: #fafafa;

		@media only screen and (min-width: $media-breakpoint-xl) {
			padding-top: 110px;
			padding-bottom: 130px;
		}

		@media only screen and (min-width: $media-breakpoint-xxl) {
			padding-top: 180px;
			padding-bottom: 200px;
		}

		.theme-dark & {
			background-color: #323232;
		}
	}

	.copy {
		@media only screen and (min-width: $media-breakpoint-md) {
			@include to-grid(width, 10, 7);
		}
	}

	.title-play {
		padding-left: 45px;
		position: relative;
		cursor: pointer;
		display: flex;
		align-items: center;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			width: 30px;
			height: 30px;
			background-size: cover;
			background-size: 100%;
			background-repeat: no-repeat;
		}

		&[data-playing='false'] {
			&:before {
				background-image: url('../../assets/svg/embedded/sound_play_black.svg');

				.theme-dark & {
					background-image: url('../../assets/svg/embedded/sound_play_white.svg');
				}
			}
		}

		&[data-playing='true'] {
			&:before {
				background-image: url('../../assets/svg/embedded/sound_pause_black.svg');

				.theme-dark & {
					background-image: url('../../assets/svg/embedded/sound_pause_white.svg');
				}
			}
		}
	}

	.video-js {
		height: 30px;
		background-color: transparent;
		width: 100%;
		padding-top: 50px;

		@media only screen and (min-width: $media-breakpoint-md) {
			padding-top: 90px;
		}

		.vjs-big-play-button {
			display: none;
		}

		.vjs-control-bar {
			background-color: transparent;
			display: flex;
		}

		.vjs-play-progress:before {
			color: $color-black;

			.theme-dark & {
				color: $color-white;
			}
		}

		.vjs-play-progress {
			background-color: $color-black;

			.theme-dark & {
				background-color: $color-white;
			}
		}

		.vjs-progress-control .vjs-progress-holder {
			margin: 0;
		}

		&.vjs-has-started .vjs-big-play-button {
			display: none;
		}

		.vjs-play-control,
		.vjs-volume-panel,
		.vjs-picture-in-picture-control,
		.vjs-fullscreen-control {
			display: none;
		}

		.vjs-current-time,
		.vjs-remaining-time {
			display: block;
			position: absolute;
			transform: translateY(100%);
		}

		.vjs-current-time,
		.vjs-remaining-time {
			color: $color-black;

			.theme-dark & {
				color: $color-white;
			}
		}

		.vjs-current-time {
			left: 0;
			padding-left: 0;
		}

		.vjs-remaining-time {
			right: 0;
			padding-right: 0;
		}
	}
}
