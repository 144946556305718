$project-bar-height: 90px;

.ProjectPage {
	width: 100%;
	--project-bar-height: #{$project-bar-height};

	&.theme-dark {
		color: $color-white;

		.project-wrapper {
			background: $color-off-black;
		}

		.down span {
			background-image: url('../../assets/svg/embedded/arrow_down_white.svg');
		}

		.project-bar.sticky {
			background-color: $color-black;
		}

		.project-bar #section-dropdown .dropdown {
			background: $color-black;
		}

		button {
			color: $color-white;
		}
	}

	.close-button {
		z-index: 3;

		&.theme-dark {
			color: $color-black;

			transition: 0.5s fill;
		}
	}

	.modules {
		& > section:not(.SectionModule) {
			margin-bottom: 60px;

			@media only screen and (min-width: $media-breakpoint-md) {
				margin-bottom: 80px;
			}

			@media only screen and (min-width: $media-breakpoint-lg) {
				margin-bottom: 160px;
			}
		}
	}

	.down  {
		span {
			width: 20px;
			height: 20px;
			display: block;
			background-image: url('../../assets/svg/embedded/arrow_down_black.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center right;
			pointer-events: none;
		}
	}

	.title-body {

		.title {
			margin-bottom: .7em;
		}

		p {
			@extend .type-p--small;
		}
	}

	.project-close {
		position: relative;
		height: 100vh;

		&.top {
			.hitter {
				height: calc(100% - #{$project-bar-height});
			}
		}

		.hitter {
			width: 100%;
			height: 100%;
		}

		.arrow {
			position: fixed;
			top: 50%;
			z-index: 1;
			transform: translateY(-50%);
			visibility: hidden;
			opacity: 0;

			svg {
				pointer-events: none;
				width: 100%;
				height: 100%;
			}

			&.theme-dark {
				circle {
					fill: $color-black;
				}

				path {
					fill: $color-white;
				}
			}

			@media (hover: hover) {
				&:hover {
					circle {
						fill: $color-black;
					}

					path {
						fill: $color-white;
					}

					&.theme-dark {
						circle {
							fill: $color-white;
						}

						path {
							fill: $color-black;
						}
					}
				}
			}

			span {
				display: block;
				width: 40px;
				height: 40px;

				@media only screen and (min-width: $media-breakpoint-xxl) {
					width: 50px;
					height: 50px;
				}
			}

			&.next-project {
				right: 20px;
				@extend .site-element-padding-right;

				span {
					transform: rotate(180deg);
				}
			}

			&.previous-project {
				left: 0;
				@extend .site-element-padding;

				span {
					transform: rotate(0deg);
				}
			}
		}
	}

	.project-wrapper {
		background: $color-white;
		position: relative;
		z-index: 2;
	}

	.project-bar {
		height: $project-bar-height;
		display: flex;
		align-items: center;

		&.sticky {
			bottom: -$project-bar-height;
			position: fixed;
			background-color: $color-white;
			width: 100%;
			z-index: 2;
			will-change: bottom;

			@media only screen and (min-width: $media-breakpoint-md) {
				overflow-y: scroll;
			}
		}

		.section-links {
			display: none;

			@media only screen and (min-width: $media-breakpoint-md) {
				grid-column-start: 5;
				grid-column-end: span 6;
				display: flex;
			}


			@media only screen and (min-width: $media-breakpoint-lg) {
				grid-column-start: 6;
				grid-column-end: span 6;
				display: flex;
			}

			&--title {
				white-space: nowrap;
				font-weight: 500;
				display: none;
				grid-column-start: 1;
				grid-column-end: span 3;

				@media only screen and (min-width: $media-breakpoint-md) {
					display: block;
				}
			}

			li:first-child {
				button {
					padding-left: 0;
				}
			}

			button {
				padding: 0 20px;
				font-weight: 300;
				white-space: nowrap;

				@media only screen and (min-width: $media-breakpoint-lg) {
					padding: 0 30px;
				}

				&.active,
				&:hover {
					//font-weight: 500;
					text-decoration: underline;
				}

				&.non-active {
					//font-weight: 300;
					text-decoration: none;
				}
			}
		}

		#section-dropdown {
			display: block;

			@media only screen and (min-width: $media-breakpoint-md) {
				display: none;
			}

			.dropdown {
				top: auto;
				bottom: 50px;
				position: absolute;
				width: 100vw;
				left: -$site-padding-mobile;
				padding: $site-padding-mobile;
				background: $color-white;

				.inner-dropdown {
					background-color: transparent;
				}
			}

			button {
				padding-left: 0;
				background-color: transparent;
			}

			.button .icon {
				transform: rotate(180deg);
			}

			&.active {
				.button .icon {
					transform: rotate(0deg);
				}
			}
		}

		.wrapper {
			width: 100%;
			display: flex;
		}

		.breadcrumbs {
			flex: 1;
			grid-column-start: 1;
			grid-column-end: span 11;
			font-weight: 500;

			.divider {
				font-weight: 300;
				margin: 0 7px;
			}

			.divider--title {
				display: none;

				@media only screen and (min-width: $media-breakpoint-md) {
					display: inline-block;
				}
			}

			.title {
				display: none;

				@media only screen and (min-width: $media-breakpoint-md) {
					display: inline-block;
				}
			}
		}

		button {

		}
	}

	.modules {
		display: flex;
		flex-direction: column;
	}

	.PanoramaModule,
	.PanZoomModule {
		.title-body {
			margin-top: 40px;

			@media only screen and (min-width: $media-breakpoint-md) {
				margin-top: 80px;
			}
		}

		.copy {
			grid-column-start: 2;
			grid-column-end: span 5;

			@media only screen and (min-width: $media-breakpoint-md) {
				grid-column-start: 2;
				grid-column-end: span 3;
			}
		}
	}
}
