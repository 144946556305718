.ImageModule {
	.media-wrapper {
		background-color: $color-image-placeholder-on-light;

		.theme-dark & {
			background-color: $color-image-placeholder-on-dark;
		}
	}

	img {
		width: 100%;
		opacity: 0;
		transition: 0.5s opacity;

		&.lazyloaded {
			opacity: 1;
		}
	}

	.grid {
		&.full {
			display: block;
			padding: 0;
			max-width: none;

			.container {
				@media only screen and (min-width: $media-breakpoint-md) {
					@include to-grid(width, 12, 3);
					@include to-grid(margin-left, 12, 1);
				}
			}

			.title-body {
				max-width: $max-page-width;
				margin: 0 auto;
				padding: 0 20px;

				@media only screen and (min-width: $media-breakpoint-md) {
					padding: 0 40px;
				}

				@media only screen and (min-width: $media-breakpoint-xl) {
					padding: 0 80px;
				}
			}
		}

		&.center {
			.media {
				grid-column-start: 4;
				grid-column-end: span 6;
			}

			.copy {
				grid-column-start: 4;
				grid-column-end: span 5;
			}
		}

		&.right {
			direction: rtl;

			& > * {
				direction: ltr;
			}
		}

		&.full {
			padding-left: 0;
			padding-right: 0;

			.media {
				grid-column-start: 1;
				grid-column-end: span 12;
				margin-bottom: 50px;
			}

			.copy {
				grid-column-start: 2;
				grid-column-end: span 5;
			}
		}

		.copy {
			grid-column-start: 9;
			grid-column-end: span 3;
			margin-top: 20px;

			@media only screen and (min-width: $media-breakpoint-md) {
				margin-top: 0;
			}
		}

		&.center .copy {
			@media only screen and (min-width: $media-breakpoint-md) {
				margin-top: 50px;
			}
		}
	}

	.media {
		grid-column-start: 2;
		grid-column-end: span 6;
	}
}
