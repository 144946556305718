.MainMenu {
	position: fixed;
	z-index: 12;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: none;
	will-change: opacity;

	.wrapper {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.close-menu-hitter {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
	}

	.menu-items {
		position: relative;
		z-index: 1;

		li {
			a {
				display: block;
				color: $color-white;
				opacity: 0.5;
				@extend .type-nav;
				line-height: 131%;

				@media (min-width: $media-breakpoint-sm) {
					line-height: 140%;
				}

				&:hover {
					opacity: 1;
				}

				&.active {
					opacity: 1;
					pointer-events: none;
				}

				&#all-shows {
					margin-top: 1em;
				}
			}
		}
	}
}

.main-menu-toggle {
	position: fixed;
	bottom: -8px;
	right: 0;
	z-index: 11;
	opacity: 0;
	visibility: hidden;

	.icon {
		display: block;
		@extend .site-element-padding;
	}

	svg {
		width: 30px;
		height: 34px;
		overflow: visible;
	}

	.menu-line {
		transition: 0.2s transform ease-out;
	}

	&:hover {
		.menu-line:nth-child(2) {
			transform: translateY(-1px);
		}

		.menu-line:nth-child(3) {
			transform: translateY(1px);
		}
	}

	&.active {
		.menu-line:nth-child(2) {
			transform: translateY(0px);
		}

		.menu-line:nth-child(3) {
			transform: translateY(0px);
		}
	}

	&.theme-dark {
		svg {
			polygon {
				fill: $color-black;
				transition: 0.5s fill;
			}
		}
	}
}

.menu-line-close {
	display: none;
}
