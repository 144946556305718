html,
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: $wigrum;
	vertical-align: baseline;
	height: 100%;
	line-height: 1.4;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
}

body {
	overflow: hidden;
	//background-color: $color-white;
	background-color: $color-black;
	color: $color-black;
}

.sf-toolbar {
	display: none !important;
}

h1,
h2,
h3,
h4 {
	font-weight: 500;
}

ul,
ol {
	list-style: none;
}

li {
	padding: 0;
	margin: 0;
}

p {
	@extend .type-p;

	a {
		color: $color-cta;

		.theme-dark & {
			color: $color-cta;
		}
	}
}

a {
	color: $color-black;

	.theme-dark & {
		color: $color-white;
	}
}

button {
	font-family: $wigrum;
}

.site-element-padding {
	padding: 30px 20px;

	@media (min-width: $media-breakpoint-md) {
		padding: 50px 30px;
	}

	@media (min-width: $media-breakpoint-lg) {
		padding: 60px 80px;
	}

	@media (min-width: $media-breakpoint-xxl) {
		padding: 80px 80px;
	}
}

.site-element-padding-right {
	@extend .site-element-padding;
	padding-right: 0;

	@media (min-width: $media-breakpoint-md) {
		padding-right: 10px;
	}

	@media (min-width: $media-breakpoint-lg) {
		padding-right: 60px;
	}

	@media (min-width: $media-breakpoint-xxl) {
		padding-right: 60px;
	}
}

.main-logo {
	position: fixed;
	z-index: 27;
	display: block;
	left: 0;
	top: 0;

	a {
		display: block;
		@extend .site-element-padding;
		padding-bottom: 0px;
		position: relative;
	}

	.b {
		width: 34px;
		position: relative;

		@media (min-width: $media-breakpoint-md) {
			width: 50px;
		}

		path {
			transition: 0.5s fill;
		}
	}

	.text {
		position: absolute;
		top: -8px;
		left: 41px;
		overflow: hidden;

		@media (min-width: $media-breakpoint-md) {
			top: 13px;
			left: 58px;
		}
	}

	&.theme-dark {
		svg {
			path {
				fill: $color-black;
				transition: 0.5s fill;
			}
		}
	}
}

.overlay-layer {
	> div {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 12;
		height: 100%;
		overflow: auto;
		overflow-x: hidden;
	}
}

.slide-page {
	display: none;
}

.ClickBlocker {
	position: fixed;
	top: 0;
	left: 0;
	//background-color: rgba(255,0,0,.1);
	z-index: 1000;
	height: 100%;
	width: 100%;
}

.media-wrapper {
	width: 100%;
	height: 0;

	&.ratio {
		position: relative;

		& > * {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.pill-button {
	font-family: 'Wigrum';
	font-weight: 500;
	background-color: $color-black;
	height: auto;
	border-radius: 20px;
	color: #ffffff;
	padding: 11px 20px;
	pointer-events: auto;
	display: inline-block;
	cursor: pointer;
	line-height: 1;
	font-size: 12px;

	@media (min-width: $media-breakpoint-md) {
		font-size: 13px;
	}

	@media (min-width: $media-breakpoint-xl) {
		font-size: 16px;
	}

	&:hover {
		background-color: $color-white;
		color: $color-black;
	}
}

.close-overlay-button,
.close-button {
	position: fixed;
	top: 0;
	right: 0px;
	z-index: 1;
	color: $color-white;
	font-weight: 500;
	text-transform: capitalize;
	@extend .type-title--body;
	@extend .site-element-padding;

	@media (min-width: $media-breakpoint-sm) {
		right: 20px;
	}

	@media (min-width: $media-breakpoint-md) {
		padding-right: 25px;
	}

	@media (min-width: $media-breakpoint-lg) {
		padding-right: 75px;
	}

	&:hover {
		text-decoration: underline;
	}
}

button {
	border: 0;
	background: transparent;
	padding: 0;
	outline: none;
	cursor: pointer;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
									 supported by Chrome, Edge, Opera and Firefox */
}

a,
a:visited,
a:active,
a:hover {
	//color: inherit;
	text-decoration: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	//border: 0;
	//font: inherit;
	//vertical-align: baseline;
}

#TemplateLayer {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	min-height: 100vh;
}

#ScrollToBottomExample {
	position: fixed;
	top: 0;
	z-index: 20;
	color: red;
	left: 0;
}

.markdown-format {
	a:hover {
		color: $color-black;
	}

	h1 {
		@extend .type-title--med;

		& + br {
			display: none;
		}

		margin-top: 50px;
		margin-bottom: 30px;

		// Title/H3 – Tablet
		@media (min-width: $media-breakpoint-md) {
			margin-top: 50px;
			margin-bottom: 40px;
		}

		// Title/H3 – Desktop
		@media (min-width: $media-breakpoint-xl) {
			margin-top: 65px;
			margin-bottom: 65px;
		}

		// Title/H3 – Desktop Large
		@media (min-width: $media-breakpoint-xxl) {
			margin-top: 80px;
			margin-bottom: 65px;
		}
	}

	ul {
		padding-left: 1.5em;

		//fix for nl2br adding <br> tags between list items
		> br {
			display: none;
		}

		li {
			@extend .type-p;
		}

		li:before {
			content: "—";
			position: absolute;
			margin-left: -1.6em;

			@media (min-width: $media-breakpoint-md) {
				margin-left: -1.3em;
			}
		}
	}

	h2, h3, h4 {
		margin-top: 25px;
	}

	> :first-child,
	h1 + br + h2,
	h1 + br + h3,
	h1 + br + h4{
		margin-top: 0;
	}

	h3 {
		@extend .type-title--body;
	}

	a {
		font-weight: 500;
	}

	margin-bottom: 60px;
}
