.ShareModule {
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		margin-bottom: 20px;
	}

	p {
		font-weight: 500;
	}

	a {
		&:hover {
			text-decoration: underline;
			color: $color-black;
		}
	}

	.theme-dark & {
		a:hover {
			color: $color-white;
		}
	}
}
