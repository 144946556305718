.PanoramaModule {
	position: relative;

	.poster-image {
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		width: 100%;
		height: 100%;
		color: white;

		cursor: pointer;
		height: 100vh;
	}

	canvas {
		position: fixed;
		cursor: grab;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		outline: none;
	}

	.loading {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 5;
		background-color: $color-black;
		width: 100%;
		height: 100%;
		visibility: hidden;
		opacity: 0;
	}

	.close {
		position: fixed;
		top: 0;
		right: 20px;
		@extend .site-element-padding-right;
		color: $color-black;
		font-weight: 500;
		font-size: 16px;
		display: none;
		z-index: 1;

		&.light {
			color: $color-white;
		}
	}
}
