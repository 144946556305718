.SlideshowModule {
	position: relative;

	.sticky {
		position: sticky;
		top: 0;
		height: 100vh;
	}

	.dummy-height {
		min-height: 100vh;
	}

	.title-wrapper {
		margin-bottom: -7vh;
	}

	.media-wrapper {
		background-color: red;
	}

	.title-body--main {
		grid-column-start: 2;
		grid-column-end: span 5;

		@media only screen and (min-width: $media-breakpoint-md) {
			grid-column-start: 2;
			grid-column-end: span 6;
		}
	}

	.images {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		height: 100%;
		align-items: center;
		will-change: transform;
		padding: 0px $site-padding-mobile;

		@media (min-width: $media-breakpoint-md) {
			padding: 0px $site-padding-tablet;
			padding-left: calc(calc(calc(100% - #{$site-padding-tablet * 2}) / 12) + #{$site-padding-tablet});
		}

		@media (min-width: $media-breakpoint-xl) {
			padding: 0 $site-padding-desktop;
			padding-left: calc(calc(calc(100% - #{$site-padding-desktop * 2}) / 12) + #{$site-padding-desktop});
		}

		@media (min-width: $max-page-width) {
			padding: 0 $site-padding-desktop;
			padding-left: calc(80px + calc(1500px / 12) + calc(calc(100% - 1660px) / 2));
		}

		//80+(1500/12)+((window.innerWidth-1660)/2)

		li {
			height: 60vh;
			margin-right: 20px;
			background-color: $color-image-placeholder-on-light;

			&:last-child {
				margin-right: 0;
			}

			.theme-dark & {
				background-color: $color-image-placeholder-on-dark;
			}

			img {
				height: 100%;
				opacity: 0;
				transition: 0.5s opacity;

				&.lazyloaded {
					opacity: 1;
				}
			}
		}
	}

	.title-body {
		margin-top: 40px;
		max-width: 550px;
	}
}
