.CursorManager {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 25;
	pointer-events: none;
	display: none;
	will-change: transform;
	transform: translateY(-50px);

	.cursor {
		display: none;
		will-change: transform;
	}

	.cursor.loader {
		width: 32px;
		height: 32px;
		background-image: url('../../assets/images/loader_circle_x2.png');
		background-size: 32px 32px;
		//background-color: red;
	}
}
