.Dropdown {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: $input-height;
	overflow: hidden;
	will-change: transform;

	&.active {
		overflow: visible;

		.selected .button .icon {
			transform: rotate(180deg);
		}

		.dropdown {
			opacity: 1;
			transform: translateY(0);
			pointer-events: auto;

			.theme-dark & {
				background-color: $color-black;
			}

			.theme-light & {
				background-color: $color-white;
			}
		}
	}

	.dropdown-wrapper {
		position: relative;
	}

	.label {
		margin-right: 30px;
		display: none;
	}

	button {
		width: 100%;
		text-align: left;
		white-space: nowrap;

		.theme-dark & {
			color: $color-white;
		}

		.theme-light & {
			color: $color-black;
		}
	}

	.selected {
		display: flex;
		height: $input-height;
		align-items: center;
		width: auto;
		position: relative;
		z-index: 1;

		.button {
			height: $input-height;
			padding: 0px ($input-padding * 2) 0 $input-padding;
			opacity: 1;
			width: 100%;
			background: $color-gray-light;

			.span {
				pointer-events: none;
			}

			.icon {
				position: absolute;
				right: 20px;
				pointer-events: none;
				height: 100%;
				top: 0;
				display: flex;
				align-items: center;
			}

			svg path {
				.theme-dark & {
					stroke: $color-white;
				}

				.theme-light & {
					stroke: $color-black;
				}
			}
		}
	}
}
